<template>
    <b-container fluid>
        <b-row class="my-4">
            <b-col md="6">
                <div v-if="date_from" class="py-2 print-show">Datum od: {{date_from | moment("DD.MM.YYYY.")}}</div>
                <b-form-group :label-cols-md="4"
                              label="Datum od:"
                              label-for="date_from">
                    <date-picker format="DD.MM.YYYY."
                                 v-model="date_from"
                                 :lang="$parent.hrv"
                                 :first-day-of-week="1"
                                 class="w-100"
                                 id="date_from">
                    </date-picker>
                </b-form-group>
            </b-col>
            <b-col md="6">
                <div v-if="date_to" class="py-2 print-show">Datum do: {{date_to | moment("DD.MM.YYYY.")}}</div>
                <b-form-group :label-cols-md="4"
                              label="Datum do:"
                              label-for="date_to">
                    <date-picker format="DD.MM.YYYY."
                                 v-model="date_to"
                                 :lang="$parent.hrv"
                                 :first-day-of-week="1"
                                 class="w-100"
                                 id="date_to">
                    </date-picker>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row class="flex-center my-2">
            <b-col>
                <table class="table b-table table-striped table-hover table-bordered text-center align-middle">
                    <thead>
                    <tr>
                        <th>
                            <h3>Knjiga izlaznih faktura - KIF</h3>
                        </th>
                    </tr>
                    </thead>
                </table>
            </b-col>
        </b-row>

        <b-row>
            <b-col v-if="kifRows">
                <roza-table :items="kifRows"
                            :fields="kifFields"
                            :prenosFields="prenosFields"
                            :prenosColspan="6"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :actions="actions"
                            @action="action"
                            print-page
                            actionHide="appended"
                            class="table-sm">
                    <template slot="custom-header">
                        <tr>
                            <th colspan="2"></th>
                            <th colspan="2">RAČUN</th>
                            <th colspan="9">KUPAC - PRIMATELJ dobra ili usluge</th>
                            <th colspan="2">REGISTRIRANI OBVEZNICI PDV-a</th>
                            <th colspan="2">KRAJNJA POTROŠNJA</th>
                        </tr>
                        <tr>
                            <th colspan="7"></th>
                            <th colspan="2">RAČUN</th>
                            <th colspan="2">NAPLAĆENO</th>
                            <th colspan="2">OSLOBOĐENO POREZA</th>
                            <th colspan="4"></th>
                        </tr>
                    </template>
                    <template slot="custom-header2">
                        <tr>
                            <th>1</th>
                            <th>a</th>
                            <th>2</th>
                            <th>3</th>
                            <th>b</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>
                            <th>c</th>
                            <th>d</th>
                            <th>8</th>
                            <th>9</th>
                            <th>10</th>
                            <th>11</th>
                            <th>12</th>
                            <th>13</th>
                        </tr>
                    </template>
                    <template slot="id_number" slot-scope="data">
                        <template v-if="data.item.associate_pdv_number">
                            {{data.item.associate_pdv_number}}
                        </template>
                        <template v-else>
                            {{data.item.associate_id_number}}
                        </template>
                    </template>
                    <template slot="datumi" slot-scope="data">
                        <template v-for="i in data.item.entry_order_rows">
                            <template v-if="i.konto_id < 8 && i.type === 1">
                                {{i.invoice_date | moment("DD.MM.YYYY.")}}<br/>
                            </template>
                        </template>
                    </template>
                    <template slot="iznos" slot-scope="data">
                        <template v-for="i in data.item.entry_order_rows">
                            <template v-if="i.konto_id < 8 && i.type === 1">
                                {{i.credit}}<br/>
                            </template>
                        </template>
                    </template>
                    <!--<template slot="ravnoteza_slot" slot-scope="data">
                        <template v-if="(data.item.associate_pdv_number && data.field.type === 0)
                                             || (!data.item.associate_pdv_number && data.field.type === 1)">
                            {{data.item.ravnoteza}}
                        </template>
                    </template>
                    <template slot="total_tax_slot" slot-scope="data">
                        <template v-if="(data.item.associate_pdv_number && data.field.type === 0)
                                           || (!data.item.associate_pdv_number && data.field.type === 1)">
                            {{data.item.total_tax_credit}}
                        </template>
                    </template>-->
                </roza-table>

                <b-pagination @input="getRows"
                              align="right"
                              v-if="kifRows"
                              :total-rows="kifRows.total"
                              v-model="currentPage"
                              :per-page="perPage">
                </b-pagination>
            </b-col>
          <b-btn @click="getCsvRows" variant="outline-success" class="float-right">Dohvati CSV</b-btn>
        </b-row>

        <set-index-modal
                ref="setIndex"
                index="kif_index"
                :item="selectedRow">
        </set-index-modal>
    </b-container>
</template>

<script>
    export default {
        name: 'Kif',
        data() {
            return {
                kifRows: null,
                date_from: null,
                date_to: null,
                variant: null,
                kifFields: [
                    {
                        key: 'kif_index',
                        label: 'Broj'
                    },
                    {
                        key: 'document_date',
                        label: 'Datum KIF-a.',
                        date: true
                    },
                    {
                        key: 'number',
                        label: 'Broj računa',
                    },
                    {
                        key: 'invoice_date',
                        label: 'Datum',
                        date: true
                    },
                    {
                        key: 'associate_code',
                        label: 'Šifra sur.'
                    },
                    {
                        key: 'associate_name',
                        label: 'NAZIV - ime i prezime i sjedište/prebivalište'
                    },
                    {
                        key: 'id_number',
                        label: 'IDB / JMBG'
                    },
                    {
                        key: 'total_debit',
                        label: 'IZNOS RAČUNA - NAKNADE S POREZOM',
                        class: 'text-right'
                    },
                    {
                        key: '',
                        label: 'IZNOS INTERNOG RAČUNA',
                    },
                    {
                        key: 'datumi',
                        label: 'Datum'
                    },
                    {
                        key: 'iznos',
                        label: 'Iznos',
                        class: 'text-right'
                    },
                    {
                        key: '',
                        label: 'Izvoz',
                    },
                    {
                        key: '',
                        label: 'Ostalo'
                    },
                    {
                        key: 'ravnoteza_pdv',
                        label: 'Osnovica',
                        class: 'text-right'
                    },
                    {
                        key: 'total_tax_credit_pdv',
                        label: 'PDV (17%)',
                        class: 'text-right'
                    },
                    {
                        key: 'ravnoteza',
                        label: 'Osnovica',
                        class: 'text-right'
                    },
                    {
                        key: 'total_tax_credit',
                        label: 'PDV (17%)',
                        class: 'text-right'
                    },
                    /*                    {
                                            key: 'ravnoteza_slot',
                                            type: 0,
                                            label: 'Osnovica',
                                            class: 'text-right'
                                        },
                                        {
                                            key: 'total_tax_slot',
                                            type: 0,
                                            label: 'PDV (17%)',
                                            class: 'text-right'
                                        },
                                        {
                                            key: 'ravnoteza_slot',
                                            type: 1,
                                            label: 'Osnovica',
                                            class: 'text-right'
                                        },
                                        {
                                            key: 'total_tax_slot',
                                            type: 1,
                                            label: 'PDV (17%)',
                                            class: 'text-right'
                                        }*/
                ],
                prenosFields: [
                    '',
                    'totalDebit',
                    {
                        colspan: 2
                    },
                    'totalCharged',
                    {
                        colspan: 2
                    },
                    'totalRavnotezaPdv',
                    'totalTaxCreditPdv',
                    'totalRavnoteza',
                    'totalTaxCredit'
                ],
                actions: {
                    index: {
                        name: 'Dodijeli redni br.',
                        icon: 'fa fa-hashtag'
                    }
                },
                currentPage: 1,
                perPage: 10,
                selectedRow: null
            }
        },
        computed: {
            selectedClient: function () {
                return this.$store.getters.selectedClient;
            }
        },
        methods: {
            getRows() {
                axiOsta.get(api.clients + this.selectedClient.id + api.taxRows + 2, {
                    params: {
                        page: this.currentPage,
                        perPage: this.perPage,
                        from: this.date_from,
                        to: this.date_to,
                        variant: this.variant,
                        year: this.$cookie.get('ezint-year')
                    }
                }).then(response => {
                    if (response.status === 200) {
                        this.kifRows = response.data;
                    }
                })
            },
            action: function (action, item) {
                switch (action) {
                    case 'index':
                        this.selectedRow = item;
                        this.$refs.setIndex.show();
                        break;
                }
            },
            getCsvRows() {
                axiOsta.post(api.clients + this.selectedClient.id + api.taxRows + 2, {
                    params: {
                        page: this.currentPage,
                        perPage: this.perPage,
                        from: this.date_from,
                        to: this.date_to,
                        variant: this.variant,
                        year: this.$cookie.get('ezint-year')
                    }
                }).then(response => {
                    if (response.status === 200) {
                        const blob = new Blob([response.data], {type: response.data.type});
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        console.log(response.headers['content-disposition']);
                        const contentDisposition = response.headers['content-disposition'];
                        let fileName = 'unknown';
                        if (contentDisposition) {
                            const fileNameMatch = contentDisposition.match(/(?<=filename=).*/);
                            fileName = fileNameMatch[0];
                        }
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        window.URL.revokeObjectURL(url);
                    }
                })
            },
        },
        created() {
            this.variant = this.$route.params.variant === 'a' ? 0 : 1;
            if (this.selectedClient != null) {
                this.getRows();
            }
            eventBus.$on('year-changed', () => {
                this.getRows();
            });
        },
        mounted() {
            this.$on('get-rows', () => this.getRows())
        },
        watch: {
            selectedClient: function (newValue, oldValue) {
                if (oldValue == null) {
                    this.getRows();
                }
            },
            date_from: function () {
                this.getRows();
            },
            date_to: function () {
                this.getRows();
            },
            '$route': function (to, from) {
                if (to.params.variant === 'a') {
                    this.variant = 0;
                } else {
                    this.variant = 1;
                }
                if (this.selectedClient != null) {
                    this.getRows();
                }
            }
        }
    }
</script>
